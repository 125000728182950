/*
jQuery CubePortfolio - Gallery Page Content -
*/


jQuery(document).ready(function() {

/*    // init cubeportfolio - za template full-width - page-content
    $('#grid-container').cubeportfolio({
    //    filters: '#js-filters-full-width',
    //    loadMore: '#js-loadMore-full-width',
    //    loadMoreAction: 'auto',
        layoutMode: 'grid',
        sortToPreventGaps: true,
    //    defaultFilter: '*',
        animationType: 'fadeOutTop',
        gapHorizontal: 6,
        gapVertical: 6,
        gridAdjustment: 'responsive',
        mediaQueries: [{
            width: 1500,
            cols: 3
        }, {
            width: 1100,
            cols: 3
        }, {
            width: 800,
            cols: 3
        }, {
            width: 480,
            cols: 2
        }, {
            width: 320,
            cols: 2
        }],
        caption: 'zoom',
        displayType: 'lazyLoading',
        displayTypeSpeed: 100,

        // lightbox
        lightboxDelegate: '.cbp-lightbox',
        lightboxGallery: true,
        lightboxTitleSrc: 'data-title'
    });*/

    // init cubeportfolio pageContent
    $('#grid-container').cubeportfolio({
/*        filters: '#js-filters-mosaic',
        loadMore: '#js-loadMore-mosaic',
        loadMoreAction: 'click',*/
        layoutMode: 'mosaic',
        sortToPreventGaps: true,
        mediaQueries: [{
            width: 1500,
            cols: 3
        }, {
            width: 1100,
            cols: 3
        }, {
            width: 800,
            cols: 3
        }, {
            width: 480,
            cols: 2
        }, {
            width: 320,
            cols: 1
        }],
 //       defaultFilter: '*',
        animationType: 'quicksand',
        gapHorizontal: 20,
        gapVertical: 20,
        gridAdjustment: 'responsive',
        caption: 'fadeIn',
        displayType: 'lazyLoading',
        //displayType: 'sequentially',
        displayTypeSpeed: 100,

        // lightbox
        lightboxDelegate: '.cbp-lightbox',
        lightboxGallery: true,
        lightboxTitleSrc: 'data-title'
    //    lightboxCounter: '<div class="cbp-popup-lightbox-counter">{{current}} of {{total}}</div>',
    });

/*
    // init cubeportfolio kareta1
    $('#grid-container-kareta1').cubeportfolio({
        filters: '#filters-container',
        /!*     loadMore: '#js-loadMore-mosaic',
         loadMoreAction: 'click',*!/
        layoutMode: 'mosaic',
        sortToPreventGaps: true,
        mediaQueries: [{
            width: 1500,
            cols: 3
        }, {
            width: 1100,
            cols: 3
        }, {
            width: 800,
            cols: 3
        }, {
            width: 480,
            cols: 2
        }, {
            width: 320,
            cols: 1
        }],
        //       defaultFilter: '*',
        animationType: 'quicksand',
        gapHorizontal: 12,
        gapVertical: 12,
        gridAdjustment: 'responsive',
        caption: 'zoom',
        displayType: 'sequentially',
        displayTypeSpeed: 100,

        // lightbox
        lightboxDelegate: '.cbp-lightbox',
        lightboxGallery: true,
        lightboxTitleSrc: 'data-title'
        //    lightboxCounter: '<div class="cbp-popup-lightbox-counter">{{current}} of {{total}}</div>',
    });


    // init cubeportfolio - za template full-width - page-gallery
    $('.grid-container-gallery').cubeportfolio({
        layoutMode: 'grid',
        sortToPreventGaps: true,
        animationType: 'fadeOutTop',
        gapHorizontal: 5,
        gapVertical: 5,
        gridAdjustment: 'responsive',
        mediaQueries: [{
            width: 1500,
            cols: 4
        }, {
            width: 1100,
            cols: 4
        }, {
            width: 800,
            cols: 3
        }, {
            width: 480,
            cols: 2
        }, {
            width: 320,
            cols: 1
        }],
        caption: 'zoom',
        displayType: 'lazyLoading',
        displayTypeSpeed: 100,

        // lightbox
        lightboxDelegate: '.cbp-lightbox',
        lightboxGallery: true,
        lightboxTitleSrc: 'data-title'
    });*/
});
